<template lang="pug">

	.landing

		section.landing__section.landing__section--contact(class="u-df-center")

			img.landing__img.landing__img--1(src="~@/assets/img/contact/Img_contact-us.jpg")
			img.landing__img.landing__img--2(src="~@/assets/img/contact/ic_contact.svg")
		
			.landing__container.landing__container--contact(class="container u-df-between")
				BaseContactFormComponent(:caption="caption" :article="article")


</template>

<script>
export default {
	name: 'ContactView',

	data() {
		return {			
			caption: '',
			article: 'You can fill out the contact us form below or give us a call at 1-800-391-8721.'
		}
	}
}
</script>

<style scoped lang="sass">
@import '~@/assets/styles/abstracts/_variables.sass'



.landing


	&__container


		&--contact


			@media screen and (max-width: $phone)
				flex-direction: column
	
	
	&__section
			

		&--contact
			min-height: 95vh

			margin-bottom: 15rem
			padding-top: 14rem

			position: relative


			@media screen and (max-width: $phone)
				margin-bottom: 0
	
	
	&__img


		&--1
			object-fit: contain
			object-position: right

			position: absolute
			
			top: 0
			right: 0

			height: 103%
			max-width: 50%

			z-index: -1


			@media screen and (max-width: $phone)
				top: 30rem
				max-width: 80%


		&--2
			object-fit: contain
			object-position: left

			position: absolute
			
			top: -23rem
			left: 0

			height: 80%
			max-width: 97%

			z-index: -1


</style>